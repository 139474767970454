export default {
	init() {
		$( document ).ready(function() {

			// Burger
			$('.header__burger').on('click', function() {
				$(this).toggleClass('header__burger-active');
				$('.header__nav').toggleClass('header__nav-active');
			});
			$('.menu-item-has-children a').on('click', function(event) {
				if($(this).parent().hasClass('menu-item-has-children')) {
					event.preventDefault();
					$(this).parent().toggleClass('menu-item-has-children-active');
					$(this).parent().children('.sub-menu').toggleClass('sub-menu-active');
				}
			});

			// following Scroll
			$('.following-scroll').on( 'click', function(event){ 
				event.preventDefault();
				let el = $(this);
				let dest = el.data("followingscroll");
				let fromTop = 90;
				console.log(dest);
				if(dest !== undefined && dest !== '') {
					$('html').animate({
						scrollTop: $(dest).offset().top - fromTop
					}, 1000
					);
				}
				return false;
			});
			
			if($(window).width() >= '768') {
				let sidebarTop  = $('.sidebar-wrap').offset().top;
				let stop_sidebar = $('.stop-sidebar').offset().top;
				// let first_block_height = $('.first-block-height').offset().top + $('.first-block-height').height();
				
				$(window).on('scroll', function(){
					let scrollTop = $(window).scrollTop();
					
					if(scrollTop >= sidebarTop && scrollTop <= stop_sidebar) {
						$('.sidebar-wrap').offset({top: scrollTop});
					}
				});
			}
		});

			



		function elementInViewport(el){
			var bounds = el.getBoundingClientRect();
			return (
				(bounds.top + bounds.height > 0) && // Елемент ниже верхней границы
				(window.innerHeight - bounds.top > 0) && // Выше нижней
				(bounds.left + bounds.width > 0) && // Правее левой
				(window.innerWidth - bounds.left > 0)// Левее правой
			);
		}
		
		let debounce = true;
		jQuery.noConflict($);

		// Ajax functions
		jQuery(document).ready(function($) {
			//find scroll position
			document.addEventListener("scroll", (e) => {
				var el = document.querySelector("#loadMore");
				var inViewport = elementInViewport(el);
				var resultView = inViewport ? true : false;

				//init
				var page = $('#loadMore').data('page');
				var catName = $('#loadMore').data('cat-name');
				var isSingle = $('#loadMore').data('is-single');
				var isFrontPage = $('#loadMore').data('is-front-page');
				var newPage = page + 1;
				var ajaxurl = $('#loadMore').data('url');
				
				//check
				if (resultView) {
					// debounce 
					if (debounce){
						debounce = false;

						//ajax call
						$.ajax({
							url: ajaxurl,
							type: 'post',
							data: {
								page: page,
								action: 'ajax_script_load_more',
								cat_name: catName,
								is_front_page: isFrontPage,
								is_single: isSingle
							},
							error: function(response) {
								console.log(response);
							},
							success: function(response) {
								//check
								if (response == 0) {
									//check
									if ($("#no-more").length == 0) {
										$('#ajax-content').append('<div id="no-more" class="text-center"></div>');
									}
									$('#loadMore').hide();
								} else {
									$('#loadMore').data('page', newPage);
									$('#ajax-content').append(response);
									debounce = true;
								}
							}
						});
					}
				}
			});
		});
	},

  	finalize() {
    	// JavaScript to be fired on all pages, after page specific JS is fired

		function handleNav() {
			let nav = $('nav.header__nav-inner > ul');
			let navWidth = nav.width();
			let navElements = nav.find('li.menu-item');
			let eleMargin = 20;
			let moreBtnText = {
				'ru-RU': 'Еще...',
				'en-US': 'More...'
			}

			let elementsSumWidth = 0;
			let elementsOff = [];
			let navBlocked = false;
			navElements.each(function() {
				let elemWidth = $(this).width() + eleMargin;
				if (((elementsSumWidth + elemWidth) < navWidth) && !navBlocked) {
					elementsSumWidth += elemWidth;
				} else {
					navBlocked = true;
					elementsOff.push($(this));
					$(this).remove();
				}
			});

			if(elementsOff.length > 0) {
				elementsOff.push(nav.find('li.menu-item:last-child'));
				nav.find('li.menu-item:last-child').remove();

				let moreBlock = '<li class="menu-item menu-item__dropdown"><span class="text-menu menu-item__dropdown-toggle">' + moreBtnText[$('html').attr('lang')] + '</span><div class="menu-item__dropdown-inner"><ul class="menu-item__dropdown-content"></ul></div></li>';

				nav.append(moreBlock);

				for(let i = 0; i < elementsOff.length; i++) {
					$('ul.menu-item__dropdown-content').append(elementsOff[i]);
				}
			}

			$('.header__nav').addClass('initialized');
		}

		if($(window).width() > 992) {
			handleNav();
		}

		$(window).on('resize', function() {
			let ww = $(window).width();
			if(ww > 992 && $('.menu-item__dropdown').length < 1) {
				handleNav();
			}

			if(ww <= 992 && $('.menu-item__dropdown').length > 0) {
				let dropdownItems = $('.menu-item__dropdown-content li');
				dropdownItems.each(function() {
					$('.header__nav-inner > ul').append($(this));
				});

				$('li.menu-item__dropdown').remove();
			}
		});
  	},
};
